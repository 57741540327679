import React, { Component } from "react";
import "./VaccinationView.css";
import VaccinationPopup from "./VaccinationPopup.js";
import XLSX from "sheetjs-style";
import * as FileSaver from "file-saver";
import { Capacitor } from "@capacitor/core";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { FileOpener } from "@capawesome-team/capacitor-file-opener";
import { Share } from "@capacitor/share";
import { Browser } from "@capacitor/browser";

class VaccinationView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      vaccinationData: [],
      remainingHorses: [],
    };
  }

  componentDidUpdate = (prevProps) => {
    if (this.props != prevProps) {
      this.loadData();
    }
  };

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    window.db.getVaccinationDataForUser(window.myid, (data) => {
      let vaccinationData = [];
      if (data.length) {
        let vaccination = [];
        data.filter((item) => {
          let curr = vaccination.find((i) => item.horseid == i.horseid);

          if (!curr) {
            vaccination.push(item);
            return true;
          } else {
            return false;
          }
        });

        for (let i in vaccination) {
          let item = vaccination[i];
          let type = window.VaccinationPage.types[item["vtype"] - 1];
          let minMonths = type.MinMonth;
          let maxMonths = type.MaxMonth;
          let minDays = type.MinDay;
          let maxDays = type.MaxDay;
          let lastDate = Date.getNewDate(item["vdate"]);
          let minDate = new Date(lastDate);
          let maxDate = new Date(lastDate);

          minDate.setMonth(minDate.getMonth() + minMonths);
          minDate = minDate.addDays(minDays);
          maxDate.setMonth(maxDate.getMonth() + maxMonths);
          maxDate = maxDate.addDays(maxDays);
          let daysLeft = Date.daysBetween(maxDate, new Date());
          vaccinationData.push({
            id: vaccination[i].id,
            lastVaccination: lastDate.formatDesh(),
            nextVaccination: maxDate.formatDesh(),
            horse: vaccination[i].HorseName,
            daysLeft: daysLeft,
            type: type.Name,
            remarks: "",
            horseid: vaccination[i].horseid,
            vtype: type,
            HorseType: vaccination[i].HorseType,
            RFBid: vaccination[i].RFBid,
            Chipid: vaccination[i].Chipid,
            stable: vaccination[i].StableName ? vaccination[i].StableName : "",
          });
        }
      }
      let horses = window.DataStorage.getAllHorses();
      let remainingHorses = horses.filter(
        (e) => vaccinationData.findIndex((i) => e.id == i.horseid) === -1
      );
      remainingHorses = remainingHorses.sort((t1, t2) => {
        if (t1.name.toLowerCase() > t2.name.toLowerCase()) return 1;
        if (t1.name.toLowerCase() < t2.name.toLowerCase()) return -1;
        return 0;
      });
      this.setState({
        vaccinationData: vaccinationData,
        remainingHorses: remainingHorses,
      });
    });
  };

  onNewVaccinationButtonClick = () => {
    let horseArr = window.DataStorage.getAllHorses();
    horseArr = horseArr.sort((h1, h2) => (h1.name > h2.name ? 1 : -1));
    window.db.getVaccinationLatest(horseArr[0].id, (rows) => {
      let vtype = null;
      if (rows.length != 0) {
        var lastItem = rows.item(0);
        var nextTypeIndex =
          window.VaccinationPage.types[lastItem["vtype"] - 1].NextIndex;
        vtype = window.VaccinationPage.types[nextTypeIndex];
      } else {
        vtype = window.VaccinationPage.types[0];
      }
      let options = {
        horseId: horseArr[0].id,
        date: new Date(),
        vtype: vtype,
      };
      let vaccinationPopup = new VaccinationPopup();
      vaccinationPopup.showVaccinationPopup(options, null, () => {
        this.loadData();
      });
    });
  };

  onEditVaccinationButtonClick = (item) => {
    let options = {
      horseId: item.horseid,
      date: new Date(item.lastVaccination),
    };
    let vaccinationPopup = new VaccinationPopup();
    vaccinationPopup.showVaccinationPopup(options, item, () => {
      this.loadData();
    });
  };

  onDeleteButtonClick = (item) => {
    window.db.setVaccinationUpdate(item.id, "d");
    window.DataStorage.deleteTrainingById(item.id);
    window.training.refreshLayout();

    window.DataGate.deleteTrainings(
      item.id,
      "d",
      item.horseid,
      item.lastVaccination,
      (code) => {
        if (code == window.DataGate.CODE_OK) {
          window.training.refresh({ showSpinner: false });
          this.loadData();
        }
      }
    );
  };

  onNewVaccinationForHorseButtonClick = (horseid) => {
    window.db.getVaccinationLatest(horseid, (rows) => {
      if (rows.length != 0) {
        var lastItem = rows.item(0);
        var nextTypeIndex =
          window.VaccinationPage.types[lastItem["vtype"] - 1].NextIndex;
      } else {
        var nextTypeIndex = 0;
      }
      var type = window.VaccinationPage.types[nextTypeIndex];
      let options = {
        horseId: horseid,
        date: new Date(),
        vtype: type,
      };
      let vaccinationPopup = new VaccinationPopup();
      vaccinationPopup.showVaccinationPopup(options, null, () => {
        this.loadData();
      });
    });
  };

  onMakePdfClick = () => {
    window.Spinner.show();
    let reportDataArr = [];
    let tasksReportArr = [];
    tasksReportArr.push([
      window.lang.lastVaccination,
      window.lang.nextVaccination,
      window.lang.horse,
      "Type",
      "Reg. nr",
      "Chipid",
      window.lang.daysLeft,
      window.lang.vaccinationType,
      window.lang.stable,
    ]);
    let vaccData = this.state.vaccinationData;
    vaccData.map((i) =>
      tasksReportArr.push([
        i.lastVaccination,
        i.nextVaccination,
        i.horse,
        i.HorseType ? i.HorseType : "None",
        i.RFBid,
        i.Chipid,
        i.daysLeft,
        i.type,
        i.stable,
      ])
    );
    reportDataArr.push({
      header: "",
      data: tasksReportArr,
    });
    let remainingHorsesReportData = [];
    let remainingHorsesArr = [];
    remainingHorsesArr.push([window.lang.horse]);
    this.state.remainingHorses.map((i) => remainingHorsesArr.push([i.name]));
    remainingHorsesReportData.push({
      header: "",
      data: remainingHorsesArr,
    });

    window.DataGate.makeVaccinationReportPdf(
      reportDataArr,
      remainingHorsesReportData,
      "Vaccination report",
      function (data, url) {
        if (Capacitor.getPlatform() == "web") {
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.target = "_blank";
          a.download = "Report.pdf";
          document.body.appendChild(a);
          a.click();
        } else {
          console.log({ url: url });
          Browser.open({ url });
        }
        window.Spinner.hide();
      }
    );
  };

  getCurrentDate = () => {
    const date = new Date();

    let currentDay = String(date.getDate()).padStart(2, "0");

    let currentMonth = String(date.getMonth() + 1).padStart(2, "0");

    let currentYear = date.getFullYear();

    return `${currentDay}-${currentMonth}-${currentYear}`;
  };

  blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  exportToExcell = async (horsesInSystemData, hosesOutSystemData) => {
    /* Start from an empty worksheet */
    var ws = XLSX.utils.aoa_to_sheet([[]]);

    var ws2 = XLSX.utils.aoa_to_sheet([[]]);
    XLSX.utils.sheet_add_aoa(ws, horsesInSystemData);
    XLSX.utils.sheet_add_aoa(ws2, hosesOutSystemData);

    /* create workbook and export */
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Horses in system");
    XLSX.utils.book_append_sheet(wb, ws2, "Horses not in system");

    window.Spinner.hide();
    if (Capacitor.getPlatform() == "web") {
      XLSX.writeFile(
        wb,
        "Vaccination_report_" + this.getCurrentDate() + ".xlsx"
      );
      return;
    }
    const excel_buffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const excel_blob = new Blob([excel_buffer], {
      type: "application/octet-stream",
    });

    const file = await Filesystem.writeFile({
      data: await this.blobToBase64(excel_blob),
      directory: Directory.Documents,
      path: "Vaccination_report_" + this.getCurrentDate() + ".xlsx",
    }).catch((error) => console.error(error));

    Share.share({ files: [file.uri] }).catch((error) => console.error(error));
  };

  onMakeXlsxClick = () => {
    if (window.multimediaMobileHelper.isActive) {
      window.multimediaMobileHelper.downloadExcell();
      return;
    }

    window.Spinner.show();
    let tasksReportArr = [];
    tasksReportArr.push([
      window.lang.lastVaccination,
      window.lang.nextVaccination,
      window.lang.horse,
      "Type",
      "Reg. nr",
      "Chipid",
      window.lang.daysLeft,
      window.lang.vaccinationType,
      window.lang.stable,
    ]);
    let vaccData = this.state.vaccinationData;
    vaccData.map((i) =>
      tasksReportArr.push([
        i.lastVaccination,
        i.nextVaccination,
        i.horse,
        i.HorseType ? i.HorseType : "None",
        i.RFBid,
        i.Chipid,
        i.daysLeft,
        i.type,
        i.stable,
      ])
    );
    let remainingHorsesData = [[window.lang.horse]];
    for (let horse of this.state.remainingHorses) {
      remainingHorsesData.push([horse.name]);
    }
    console.log(remainingHorsesData);
    this.exportToExcell(tasksReportArr, remainingHorsesData);
  };

  render() {
    const vaccinationData = this.state.vaccinationData.sort(
      (a, b) => a.daysLeft - b.daysLeft
    );
    const { remainingHorses } = this.state;
    return (
      <div id="vaccinationViewDiv">
        <div className="topRow">
          <button
            className="ui-btn ui-shadow ui-corner-all"
            onClick={this.onNewVaccinationButtonClick}
          >
            {window.lang.newVaccination}
          </button>
          <img
            id="makePDFButton0"
            className="reportVaccButton"
            src="img/pdf_download.png"
            onClick={this.onMakePdfClick}
          />
          <img
            id="makeXlsxButton0"
            className="reportVaccButton"
            src="img/excel_download.png"
            onClick={this.onMakeXlsxClick}
          />
        </div>
        <table className="VaccinationTable">
          <tbody>
            <tr>
              <th>{window.lang.lastVaccination}</th>
              <th>{window.lang.nextVaccination}</th>
              <th>{window.lang.horse}</th>
              <th>Type</th>
              <th>Reg. nr</th>
              <th>Chipid</th>
              <th>{window.lang.daysLeft}</th>
              <th>{window.lang.vaccinationType}</th>
              <th>{window.lang.remark}</th>
              <th></th>
              <th></th>
            </tr>
            {vaccinationData.length ? (
              vaccinationData.map((i) => (
                <tr key={Math.uuid()}>
                  <td>{i.lastVaccination}</td>
                  <td>{i.nextVaccination}</td>
                  <td>{i.horse}</td>
                  <td>{i.HorseType ? i.HorseType : "None"}</td>
                  <td>{i.RFBid}</td>
                  <td>{i.Chipid}</td>
                  <td
                    style={
                      i.daysLeft > 0 ? { color: "black" } : { color: "red" }
                    }
                  >
                    {i.daysLeft}
                  </td>
                  <td>{i.type}</td>
                  <td>{i.remarks}</td>
                  <td>
                    <img
                      className="actionIcon"
                      src="img/edit_icon.png"
                      onClick={() => this.onEditVaccinationButtonClick(i)}
                    />
                    <img
                      className="actionIcon"
                      src="img/trashcan.png"
                      onClick={() => this.onDeleteButtonClick(i)}
                    />
                  </td>
                  <td>
                    <button
                      className="ui-btn ui-shadow ui-corner-all"
                      onClick={() =>
                        this.onNewVaccinationForHorseButtonClick(i.horseid)
                      }
                    >
                      {window.lang.newVaccination}
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <></>
            )}
          </tbody>
        </table>
        <h3 style={{ marginLeft: "20px" }}>
          {window.lang.remainingHorsesVacc}
        </h3>
        <table
          className="remainingHorsesVaccinationTable"
          style={{ borderCollapse: "collapse" }}
        >
          <tbody>
            <tr>
              <th>{window.lang.horse}</th>
              <th></th>
            </tr>
            {remainingHorses.length ? (
              remainingHorses.map((i) => (
                <tr key={Math.uuid()}>
                  <td>{i.name}</td>
                  <td>
                    <button
                      className="ui-btn ui-shadow ui-corner-all"
                      onClick={() =>
                        this.onNewVaccinationForHorseButtonClick(i.id)
                      }
                    >
                      {window.lang.newVaccination}
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <></>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default VaccinationView;

import React, { Component } from "react";
import './TeethView.css';

export default class TeethView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            teethData: []
        }
    }

    componentDidUpdate = (prevProps) => {
        if (this.props != prevProps) {
            this.loadData();
        }
    }

    componentDidMount = () => {
        this.loadData();
    }

    loadData = () => {
        window.Spinner.show();
        window.db.getTeethDataForUser(window.myid, (data) => {
            let teethData = [];
            if (data.length) {
                let teeth = [];
                data.filter(item => {
                    let curr = teeth.find(i => item.horseid == i.horseid);
                    if (!curr) {
                        teeth.push(item);
                        return true;
                    } else {
                        return false;
                    }
                });

                for (let i = 0; i < teeth.length; i++) {
                    teethData.push({
                        id: teeth[i].Id, horseId: teeth[i].horseid, horse: teeth[i].Name, RFBid: teeth[i].RFBid, bornIn: teeth[i].BirthYear,
                        remarks: teeth[i].Remark, starttime: teeth[i].starttime
                    });
                }

                teethData = teethData.sort(function (h1, h2) {
                    return (h1.horse.toLowerCase() > h2.horse.toLowerCase()) ? 1 : -1;
                });

                this.setState({
                    teethData: teethData
                }, window.Spinner.hide());
            }
            else
                window.Spinner.hide();
        });
    }

    render() {
        const { teethData } = this.state;
        return (
            <div id="teethViewDiv">
                <table className="TeethTable">
                    <tbody>
                        <tr>
                            <th>
                                {window.lang.horse}
                            </th>
                            <th>
                                Reg. nr
                            </th>
                            <th>
                                {window.lang.bornIn}
                            </th>
                            <th>
                                {window.lang.date}
                            </th>
                            <th>
                                {window.lang.remark}
                            </th>
                        </tr>
                        {teethData.length ?
                            teethData.map(i =>
                                <tr key={Math.uuid()}>
                                    <td>{i.horse}</td>
                                    <td>{i.RFBid}</td>
                                    <td>{i.bornIn}</td>
                                    <td>{i.starttime}</td>
                                    <td>{i.remarks}</td>
                                </tr>
                            )
                            : <></>
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}

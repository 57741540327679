import React, { useState, use, useEffect } from "react";
import ReactDOM from "react-dom";
import "./FieldManager.css";
import Modal from "../Modal/Modal";
function FieldManager() {
  /**
   * Type definitions:
   *
   * type Field {
   *  id: String,
   *  name: String,
   *  maxNumberHorses: Integer
   * }
   *
   *
   * type TimePeriods {
   *  id: String,
      period: String
   * }
   *
   *
   * type FieldArea {
   *  id: String,
   *  name: String,
   *  fields: Field[],
   *  timePeriods: TimePeriods[],
   * }
   */

  //   {
  //     id: "1",
  //     name: "Hingste fold",
  //     fields: [
  //       {
  //         id: "1",
  //         name: "H1",
  //       },
  //       {
  //         id: "2",
  //         name: "H2",
  //       },
  //       {
  //         id: "3",
  //         name: "H3",
  //       },
  //     ],
  //     time_periods: [
  //       {
  //         id: "1",
  //         period: "10-12",
  //       },
  //       {
  //         id: "2",
  //         period: "12-14",
  //       },
  //     ],
  //   },
  // ];
  const [fieldAreas, setFieldAreas] = useState([]);

  // MODALS
  const [showCreateFieldAreaModal, setShowCreateFieldAreaModal] =
    useState(false);

  const [showCreateFieldModal, setShowCreateFieldModal] = useState(false);

  const [showEditFieldModal, setShowEditFieldModal] = useState(false);
  const [showEditTimePeriodModal, setShowEditTimePeriodModal] = useState(false);

  const [showCreateTimeModal, setShowCreateTimeModal] = useState(false);

  const [showDeleteFieldModal, setShowDeleteFieldModal] = useState(false);
  const [showDeleteFieldAreaModal, setShowDeleteFieldAreaModal] =
    useState(false);

  const [showDeleteTimePeriodModal, setShowDeleteTimePeriodModal] =
    useState(false);

  // #SECTION Data creation
  const [currentWorkingFieldAreaId, setCurrentWorkingFieldAreaId] =
    useState("");

  const [currentWorkingFieldId, setCurrentWorkingFieldId] = useState("");
  const [currentWorkingTimePeriodId, setCurrentWorkingTimePeriodId] =
    useState("");

  const [newFieldAreaName, setNewFieldAreaName] = useState("");
  const [newFieldName, setNewFieldName] = useState("");
  const [newFieldNumberOfHorses, setNewFieldNumberOfHorses] = useState(1);
  const [newTimePeriod, setNewTimePeriod] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  //   reset the value of the fields inside the model
  useEffect(() => {
    setNewFieldAreaName("");
    setNewFieldName("");
    setNewTimePeriod("");
    setNewFieldNumberOfHorses(1);
  }, [showCreateFieldAreaModal, showCreateFieldModal, showCreateTimeModal]);

  const fetchData = () => {
    window.DataGate.getFieldAreas((code, data) => {
      if (data) setFieldAreas(data);
    });
  };
  useEffect(() => {
    fetchData();
  }, []);

  const move_field = (fields, index, field_area_index, higher) => {
    if (higher && index == 0) return;

    if (!higher && index == fields.length - 1) return;

    let secondIndex = higher ? index - 1 : index + 1;
    let firstFieldId = fields[index].id;
    let secondFieldId = fields[secondIndex].id;

    window.DataGate.moveField(
      {
        firstFieldId,
        secondFieldId,
      },
      (status, data) => {}
    );
    let aux = [...fields];

    let x = aux[index];
    aux[index] = fields[secondIndex];
    aux[secondIndex] = x;

    let aux2 = [...fieldAreas];
    aux2[field_area_index].fields = aux;
    setFieldAreas(aux2);
  };
  //   #ENDSECTION
  const generateFieldAreaFieldTables = (field_area, field_area_index) => {
    if (field_area.fields.length == 0 && !field_area.is_editable) return;
    const firstColumnColSpan = field_area.is_editable ? 1 : 2;
    return (
      <table className="field-area-section--table">
        <thead>
          <tr>
            <th colSpan={firstColumnColSpan}>
              <span>{window.lang.fieldName}</span>
            </th>
            <th className="field-area-section--table--header-text">
              <span>{window.lang.numberOfHorses}</span>
            </th>
            {field_area.is_editable && <th></th>}
          </tr>
        </thead>
        <tbody>
          {field_area.fields.map((field, fieldIndex) => (
            <tr key={field_area.id + "-field-" + field.id}>
              <td
                colSpan={firstColumnColSpan}
                className="field-area-section--table--field-name"
              >
                <div className="field-area-section--table--field-name--inner">
                  <div
                    onClick={() =>
                      move_field(
                        field_area.fields,
                        fieldIndex,
                        field_area_index,
                        true
                      )
                    }
                  >
                    <svg
                      width="25px"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18"
                      />
                    </svg>
                  </div>

                  <div
                    onClick={() =>
                      move_field(
                        field_area.fields,
                        fieldIndex,
                        field_area_index,
                        false
                      )
                    }
                  >
                    <svg
                      width="25px"
                      style={{ transform: "rotate(180deg)" }}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18"
                      />
                    </svg>
                  </div>
                  <span>{field.name}</span>
                </div>
              </td>
              <td className="field-area-section--table--field-horses">
                {field.maxNumberHorses}
              </td>
              {field_area.is_editable && (
                <td className="field-area-section--table--field-button">
                  <div
                    onClick={() => {
                      setCurrentWorkingFieldAreaId(field_area.id);
                      setCurrentWorkingFieldId(field.id);
                      setShowDeleteFieldModal(true);
                    }}
                  >
                    <img
                      loading="lazy"
                      src="img/trashcan.png"
                      className="tcDelButton"
                    />
                  </div>
                </td>
              )}
              {field_area.is_editable && (
                <td className="field-area-section--table--field-button">
                  <div
                    onClick={() => {
                      setCurrentWorkingFieldAreaId(field_area.id);
                      setCurrentWorkingFieldId(field.id);
                      setNewFieldName(field.name);
                      setNewFieldNumberOfHorses(field.maxNumberHorses);
                      setShowEditFieldModal(true);
                    }}
                  >
                    <img
                      loading="lazy"
                      src="img/edit_icon.png"
                      className="tcDelButton"
                    />
                  </div>
                </td>
              )}
            </tr>
          ))}

          {field_area.is_editable && (
            <tr>
              <td className="field-area-section--table--field-name">
                <button
                  className="ui-btn field-btn"
                  onClick={() => {
                    setCurrentWorkingFieldAreaId(field_area.id);
                    setShowCreateFieldModal(true);
                  }}
                >
                  Add Field
                </button>
              </td>
              <td className="field-area-section--table--field-button"></td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  const generateFieldAreaTimePeriodTables = (field_area) => {
    if (field_area.timePeriods.length == 0 && !field_area.is_editable) return;
    const firstColumnColSpan = field_area.is_editable ? 1 : 2;
    return (
      <table className="field-area-section--table">
        <thead>
          <tr>
            <th colSpan={firstColumnColSpan}>
              <span>{window.lang.timePeriod}</span>
            </th>
            {field_area.is_editable && <th></th>}
          </tr>
        </thead>
        <tbody>
          {field_area.timePeriods.map((time_period) => (
            <tr key={field_area.id + "-time-" + time_period.id}>
              <td
                colSpan={firstColumnColSpan}
                className="field-area-section--table--field-name"
              >
                <span>{time_period.period}</span>
              </td>
              {field_area.is_editable && (
                <td className="field-area-section--table--field-button">
                  <div
                    onClick={() => {
                      setCurrentWorkingFieldAreaId(field_area.id);
                      setCurrentWorkingTimePeriodId(time_period.id);
                      setShowDeleteTimePeriodModal(true);
                    }}
                  >
                    <img
                      loading="lazy"
                      src="img/trashcan.png"
                      className="tcDelButton"
                    />
                  </div>
                </td>
              )}
              {field_area.is_editable && (
                <td className="field-area-section--table--field-button">
                  <div
                    onClick={() => {
                      setCurrentWorkingFieldAreaId(field_area.id);
                      setCurrentWorkingTimePeriodId(time_period.id);
                      setNewTimePeriod(time_period.period);
                      setShowEditTimePeriodModal(true);
                    }}
                  >
                    <img
                      loading="lazy"
                      src="img/edit_icon.png"
                      className="tcDelButton"
                    />
                  </div>
                </td>
              )}
            </tr>
          ))}

          {field_area.is_editable && (
            <tr>
              <td className="field-area-section--table--field-name">
                <button
                  className="ui-btn field-btn"
                  onClick={() => {
                    setCurrentWorkingFieldAreaId(field_area.id);
                    setShowCreateTimeModal(true);
                  }}
                >
                  Add Time Period
                </button>
              </td>
              <td className="field-area-section--table--field-button"></td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  const renderCreateFieldDefaulModalBody = (ev_dispatcher, ev_value) => {
    return (
      <>
        <input
          type="text"
          value={ev_value}
          onChange={(e) => ev_dispatcher(e.currentTarget.value)}
          placeholder={window.lang.name}
          className="field-manager--modal-text-input"
        />
      </>
    );
  };

  const renderCreateFieldModalBody = (
    ev_dispatcher,
    ev_value,
    second_ev_dispatcher,
    second_ev_value
  ) => {
    return (
      <div className="field-manager--modal--holder">
        <span style={{ marginTop: "-10px" }}>{window.lang.name}</span>
        <input
          type="text"
          value={ev_value}
          onChange={(e) => ev_dispatcher(e.currentTarget.value)}
          placeholder={window.lang.name}
          className="field-manager--modal-text-input"
        />

        <span style={{ marginTop: "15px" }}>{window.lang.numberOfHorses}</span>
        <input
          type="number"
          value={second_ev_value}
          onChange={(e) => second_ev_dispatcher(e.currentTarget.value)}
          placeholder={window.lang.numberOfHorses}
          className="field-manager--modal-text-input"
        />
      </div>
    );
  };

  const renderDeleteModalBody = () => {
    return <span>{window.lang.wantDelete}</span>;
  };

  const createFieldArea = () => {
    if (newFieldAreaName == "") {
      alert("Please select a valid name for the field area");
      return;
    }

    window.Spinner.show();
    // create an area
    window.DataGate.addFieldArea(
      {
        name: newFieldAreaName,
      },
      (code, data) => {
        setFieldAreas(data);
        window.Spinner.hide();
      }
    );

    setShowCreateFieldAreaModal(false);
  };
  const createField = () => {
    if (newFieldName == "") {
      alert("Please select a valid name for the field");
      return;
    }

    window.Spinner.show();
    // create an area
    window.DataGate.addField(
      {
        name: newFieldName,
        numberOfHorses: newFieldNumberOfHorses,
        fieldAreaId: currentWorkingFieldAreaId,
      },
      (code, data) => {
        setFieldAreas(data);
        window.Spinner.hide();
      }
    );

    setShowCreateFieldModal(false);
  };
  const editField = () => {
    if (newFieldName == "") {
      alert("Please select a valid name for the field");
      return;
    }

    window.Spinner.show();
    // create an area
    window.DataGate.editField(
      {
        name: newFieldName,
        numberOfHorses: newFieldNumberOfHorses,
        fieldId: currentWorkingFieldId,
      },
      (code, data) => {
        setFieldAreas(data);
        window.Spinner.hide();
      }
    );

    setShowEditFieldModal(false);
  };

  const createTimePeriod = () => {
    if (newTimePeriod == "") {
      alert("Please select a valid time period text");
      return;
    }

    window.Spinner.show();
    // create an area
    window.DataGate.addFieldTimePeriod(
      {
        period: newTimePeriod,
        fieldAreaId: currentWorkingFieldAreaId,
      },
      (code, data) => {
        setFieldAreas(data);
        window.Spinner.hide();
      }
    );

    setShowCreateTimeModal(false);
  };

  const editFieldTimePeriod = () => {
    if (newTimePeriod == "") {
      alert("Please select a valid time period text");
      return;
    }

    window.Spinner.show();
    // create an area
    window.DataGate.editFieldTimePeriod(
      {
        period: newTimePeriod,
        timePeriodId: currentWorkingTimePeriodId,
      },
      (code, data) => {
        setFieldAreas(data);
        window.Spinner.hide();
      }
    );

    setShowEditTimePeriodModal(false);
  };

  const deleteFieldAreaItem = () => {
    window.Spinner.show();

    // delete field

    if (showDeleteFieldModal) {
      window.DataGate.deleteFieldFromAreaField(
        {
          id: currentWorkingFieldId,
        },
        (code, data) => {
          setFieldAreas(data);
          window.Spinner.hide();
        }
      );
      setShowDeleteFieldModal(false);
      return;
    }

    // delete time period
    if (showDeleteTimePeriodModal) {
      window.DataGate.deleteTimePeriodFromAreaField(
        {
          id: currentWorkingTimePeriodId,
        },
        (code, data) => {
          setFieldAreas(data);
          window.Spinner.hide();
        }
      );
      setShowDeleteTimePeriodModal(false);
      return;
    }

    // delete field area
    window.DataGate.deleteFieldArea(
      {
        id: currentWorkingFieldAreaId,
      },
      (code, data) => {
        setFieldAreas(data);
        window.Spinner.hide();
      }
    );
    setShowDeleteFieldAreaModal(false);
  };

  const renderDeleteTitle = () => {
    if (showDeleteFieldModal) return "Delete field";
    if (showDeleteTimePeriodModal) return "Delete time period";

    return "Delete field area";
  };

  const move_task = (index, higher) => {
    window.Spinner.show();

    if (isLoading) return;
    setIsLoading(true);
    let items = [...fieldAreas];
    if (index == 0 && higher) return;
    if (index == items.length - 1 && !higher) return;

    let newIndex = higher ? index - 1 : index + 1;
    let aux = items[index];
    items[index] = items[newIndex];
    items[newIndex] = aux;
    const ids = items.map((task) => task.id);
    window.DataGate.reorderFieldAreas(ids, () => {
      setFieldAreas(items);
      setIsLoading(false);
      window.Spinner.hide();
    });
  };

  return (
    <>
      <div className="field-manager--scroll-area">
        <div className="field-manager--holder">
          {fieldAreas &&
            fieldAreas.map((field_area, field_area_index) => (
              <section key={field_area.id} className="field-area-section">
                <div className="field-area-section--title-holder">
                  <div onClick={() => move_task(field_area_index, true)}>
                    <svg
                      width="25px"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18"
                      />
                    </svg>
                  </div>

                  <div onClick={() => move_task(field_area_index, false)}>
                    <svg
                      width="25px"
                      style={{ transform: "rotate(180deg)" }}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18"
                      />
                    </svg>
                  </div>
                  <h3>
                    {window.lang.fieldArea}:{" "}
                    <span className="field-area-section--title">
                      {field_area.name}
                    </span>
                  </h3>
                  <img
                    src="img/trashcan.png"
                    onClick={() => {
                      setCurrentWorkingFieldAreaId(field_area.id);
                      setShowDeleteFieldAreaModal(true);
                    }}
                    className="tcDelButton--field-area"
                  />
                </div>

                <div className="field-area-section--data-holder">
                  {generateFieldAreaFieldTables(field_area, field_area_index)}
                  {generateFieldAreaTimePeriodTables(field_area)}
                </div>
              </section>
            ))}
        </div>
        <button
          className="ui-btn field-area-btn"
          onClick={() => setShowCreateFieldAreaModal(true)}
        >
          Add Field Area
        </button>
      </div>

      {showCreateFieldAreaModal && (
        <Modal
          title="Create an area field"
          body={renderCreateFieldDefaulModalBody(
            setNewFieldAreaName,
            newFieldAreaName
          )}
          button1_text={window.lang.add}
          button2_text={window.lang.cancel2}
          button2_callback={() => setShowCreateFieldAreaModal(false)}
          button1_callback={createFieldArea}
        />
      )}

      {showEditFieldModal && (
        <Modal
          title="Edit field"
          body={renderCreateFieldModalBody(
            setNewFieldName,
            newFieldName,
            setNewFieldNumberOfHorses,
            newFieldNumberOfHorses
          )}
          button1_text={window.lang.edit}
          button2_text={window.lang.cancel2}
          button2_callback={() => setShowEditFieldModal(false)}
          button1_callback={editField}
        />
      )}
      {showCreateFieldModal && (
        <Modal
          title="Create a field"
          body={renderCreateFieldModalBody(
            setNewFieldName,
            newFieldName,
            setNewFieldNumberOfHorses,
            newFieldNumberOfHorses
          )}
          button1_text={window.lang.add}
          button2_text={window.lang.cancel2}
          button2_callback={() => setShowCreateFieldModal(false)}
          button1_callback={createField}
        />
      )}

      {showCreateTimeModal && (
        <Modal
          title="Create a time period"
          body={renderCreateFieldDefaulModalBody(
            setNewTimePeriod,
            newTimePeriod
          )}
          button1_text={window.lang.add}
          button2_text={window.lang.cancel2}
          button2_callback={() => setShowCreateTimeModal(false)}
          button1_callback={createTimePeriod}
        />
      )}

      {showEditTimePeriodModal && (
        <Modal
          title="Edit time period"
          body={renderCreateFieldDefaulModalBody(
            setNewTimePeriod,
            newTimePeriod
          )}
          button1_text={window.lang.edit}
          button2_text={window.lang.cancel2}
          button2_callback={() => setShowEditTimePeriodModal(false)}
          button1_callback={editFieldTimePeriod}
        />
      )}

      {(showDeleteFieldModal ||
        showDeleteTimePeriodModal ||
        showDeleteFieldAreaModal) && (
        <Modal
          title={renderDeleteTitle()}
          body={renderDeleteModalBody()}
          button1_text={window.lang.deleteL}
          button2_text={window.lang.cancel2}
          button2_callback={() => {
            setShowDeleteFieldModal(false);
            setShowDeleteTimePeriodModal(false);
            setShowDeleteFieldAreaModal(false);
          }}
          button1_callback={deleteFieldAreaItem}
        />
      )}
    </>
  );
}

export default class FieldManagerHolder {
  createFieldManager() {
    ReactDOM.render(
      <FieldManager />,
      document.getElementById("fieldManagementPageReact")
    );
  }
}

import React, { Component } from "react";
import './DewormingView.css';

export default class DewormingView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dewormingData: []
        }
    }

    componentDidUpdate = (prevProps) => {
        if (this.props != prevProps) {
            this.loadData();
        }
    }

    componentDidMount = () => {
        this.loadData();
    }

    loadData = () => {
        window.Spinner.show();
        window.db.getDewormingDataForUser(window.myid, (data) => {
            let dewormingData = [];
            let dewormingDataWithNullNextDeworming = [];
            if (data.length) {
                let deworming = [];
                data.filter(item => {
                    let curr = deworming.find(i => item.horseid == i.horseid);
                    if (!curr) {
                        deworming.push(item);
                        return true;
                    } else {
                        return false;
                    }
                });

                for (let i = 0; i < deworming.length; i++) {
                    let nextDewormingDate = '';
                    if (deworming[i].NextDewormingPeriod != null) {
                        switch (deworming[i].NextDewormingPeriod) {
                            case '0':
                                var date = new Date(JSON.parse(JSON.stringify(deworming[i].starttime)));
                                date = new Date(date.setMonth(date.getMonth() + 1))
                                nextDewormingDate = date.formatDesh();
                                break;
                            case '1':
                                var date = new Date(JSON.parse(JSON.stringify(deworming[i].starttime)));
                                date = new Date(date.setMonth(date.getMonth() + 3))
                                nextDewormingDate = date.formatDesh();
                                break;
                            case '2':
                                var date = new Date(JSON.parse(JSON.stringify(deworming[i].starttime)));
                                date = new Date(date.setMonth(date.getMonth() + 6))
                                nextDewormingDate = date.formatDesh();
                                break;
                            case '3':
                                var date = new Date(JSON.parse(JSON.stringify(deworming[i].starttime)));
                                date = new Date(date.setMonth(date.getMonth() + 12))
                                nextDewormingDate = date.formatDesh();
                                break;
                        }
                        dewormingData.push({
                            id: deworming[i].Id, horseId: deworming[i].horseid, horse: deworming[i].Name, RFBid: deworming[i].RFBid, bornIn: deworming[i].BirthYear,
                            remarks: deworming[i].Remark, starttime: deworming[i].starttime, nextDewormingDate: nextDewormingDate, dewormingTypeName: deworming[i].DewormingTypeName
                        });
                    }
                    else {
                        dewormingDataWithNullNextDeworming.push({
                            id: deworming[i].Id, horseId: deworming[i].horseid, horse: deworming[i].Name, RFBid: deworming[i].RFBid, bornIn: deworming[i].BirthYear,
                            remarks: deworming[i].Remark, starttime: deworming[i].starttime, nextDewormingDate: nextDewormingDate, dewormingTypeName: deworming[i].DewormingTypeName
                        });
                    }
                    
                }
                dewormingData = dewormingData.sort(function (h1, h2) {
                    return (h1.nextDewormingDate < h2.nextDewormingDate) ? -1 : 1;
                });

                dewormingData.push(...dewormingDataWithNullNextDeworming);
                this.setState({
                    dewormingData: dewormingData
                }, window.Spinner.hide());
            }
        });
    }

    render() {
        const { dewormingData } = this.state;
        return (
            <div id="dewormingViewDiv">
                <table className="DewormingTable">
                    <tbody>
                        <tr>
                            <th>
                                {window.lang.date}
                            </th>
                            <th>
                                {window.lang.nextDeworming}
                            </th>
                            <th>
                                {window.lang.horse}
                            </th>
                            <th>
                                Reg. nr
                            </th>
                            <th>
                                {window.lang.bornIn}
                            </th>
                            <th>
                                Deworming type
                            </th>
                            <th>
                                {window.lang.remark}
                            </th>
                        </tr>
                        {dewormingData.length ?
                            dewormingData.map(i =>
                                <tr key={Math.uuid()}>
                                    <td>{i.starttime}</td>
                                    <td>{i.nextDewormingDate}</td>
                                    <td>{i.horse}</td>
                                    <td>{i.RFBid}</td>
                                    <td>{i.bornIn}</td>
                                    <td>{i.dewormingTypeName}</td>
                                    <td>{i.remarks}</td>
                                </tr>
                            )
                            : <></>
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}
import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";
import TrainingExtension from "./TrainingViews/TrainingExtension";
import HomePageExtention from "./Home/HomePageExtention";
import HorsePageExtention from "./AddEditHorse/HorsePageExtention";
import MultimediaMobileHelper from "./Multimedia/MultimediaMobileHelper";
import { SideMenuExtention } from "./Menu/SideMenu";
import Report from "./Report/Report";
import RiderRole from "./RiderRole/RiderRole";
import Sharing from "./SharingPage/SharingPage";
import TrainingPopup from "./TrainingPopup/TrainingPopup";
import SharePage from "./SharingPage/SharePage";
import AutoCreateTaskPage from "./AutoCreateTask/AutoCreateTaskPage";
import VaccinationPopup from "./Home/VaccinationView/VaccinationPopup";
import PriceReport from "./Home/Settings/PriceReport";
import DewormingTypes from "./Home/Settings/DewormingTypes";
import ReportSettings from "./Report/ReportSettings";
import MultimediaHelper from "./Multimedia/MultimediaHelper";
import SendSalesData from "./SendSalesData/SendSalesData";
import { PublicHorsePresentationHolder } from "./SendSalesData/SendSalesData";
import FieldManager from "./FieldManager/FieldManager";

window.trainingExtension = new TrainingExtension();
window.HomePage.extention = new HomePageExtention();
window.Managers.extention = new HorsePageExtention();
window.multimediaMobileHelper = new MultimediaMobileHelper();
// window.multimediaHelper = new MultimediaHelper();
window.TopMenu.Self.sidebarExtention = new SideMenuExtention();
window.vetfartable.extention = new Report();
window.sendSalesData = new SendSalesData();
window.fieldManager = new FieldManager();
window.showPublicPresentation = new PublicHorsePresentationHolder();
window.window.managers = new RiderRole();
window.managers.extention = new Sharing();
window.TrainingPopup = TrainingPopup;
window.SharePage = SharePage;
window.AutoCreateTaskPage = AutoCreateTaskPage;
window.VaccinationPage.extention = new VaccinationPopup();
window.managers.priceReport = new PriceReport();
window.managers.dewormingTypes = new DewormingTypes();
window.managers.reportSettings = new ReportSettings();

// ReactDOM.render(<React.StrictMode></React.StrictMode>, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import ReactDOM from "react-dom";
import React, { Component } from "react";
import Select from "react-select";
import "./SideMenu.css";
import TrainingPopup from "../TrainingPopup/TrainingPopup";

class SideMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showTrianingGroup: false,
      showFilterGroup: false,
      riders: [],
      riderValue: [],
      horseTypes: [],
      selectedHorseType: [],
      stables: [],
      selectedStable: [],
      labels: [],
      selectedLabels: [],
    };
  }

  componentDidMount = () => {
    this.loadData();
  };

  componentDidUpdate(prevProps) {
    if (this.props.time !== prevProps.time) {
      this.loadData();
    }
  }

  loadData = () => {
    if (window.myid) {
      window.db.getAllUsersExceptId(window.myid, (dataU) => {
        const riders = [
          { value: 1, label: window.lang.showAllRiders },
          { value: window.myid, label: window.lang.me },
          ...dataU.map((t) => ({ value: t.id, label: t.name })),
        ];

        const riderArr = JSON.parse(
          window.GlobalData.getItem(window.GlobalData.FILTER_RIDER)
        );
        const selectedRiderArr = riders.filter((i) => riderArr.indexOf(i.value) != -1);

        this.setState({
          riders: riders,
          riderValue: selectedRiderArr,
        });
      });

      window.db.getHorseTypesBySelector(window.myid, (types) => {
        window.DataStorage.horseTypes = types;
        let typesMap = types.map((t) => ({ value: t.ID, label: t.Name }));
        let hTypes = [];
        typesMap.filter((item) => {
          let curr = hTypes.find((i) => i.label === item.label);
          if (!curr) {
            hTypes.push(item);
            return true;
          } else {
            curr.value += "," + item.value;
            return false;
          }
        });

        const horseTypes = [
          { value: 1, label: window.lang.showAllHorseTypes },
          { value: 2, label: window.lang.showInTraining },
          { value: 3, label: window.lang.showOwnInTraining },
          ...hTypes,
          { value: 101, label: window.lang.historical },
        ];

        const sarr = JSON.parse(
          window.GlobalData.getItem(window.GlobalData.SHOW_IN_TRAINING)
        );
        const selectedArr = horseTypes.filter((t) => sarr.indexOf(t.value) != -1);

        this.setState({
          horseTypes: horseTypes,
          selectedHorseType: selectedArr,
        });

        window.DataStorage.clearAllHorses();
      });

      window.db.getAllStables(window.myid, (stablesData) => {
        window.db.getAllStablesSharedHorses(window.myid, (stablesShared) => {
          console.log(stablesShared);
          window.DataStorage.stables = stablesData;
          const stables = [
            { value: 1, label: window.lang.showAllStables },
            ...stablesData.map((t) => ({ value: t.Id, label: t.StableName })),
            ...stablesShared.map((t) => ({ value: t.Id, label: t.StableName })),
          ];

          const stableArr = JSON.parse(
            window.GlobalData.getItem(window.GlobalData.SHOW_IN_STABLE)
          );
          const selectedStableArr = stables.filter(
            (t) => stableArr.indexOf(t.value) != -1
          );

          this.setState({
            stables: stables,
            selectedStable: selectedStableArr,
          });
        });
      });

      window.DataStorage.fetchHorseLabels(() => {
        let labels = [{ value: 1, label: "All Labels" }];
        if (window.DataStorage.horseLabels)
          labels = [
            ...labels,
            ...window.DataStorage.horseLabels.map((t) => ({
              value: t.Id,
              label: t.Label,
            })),
          ];
        const labelsArr = JSON.parse(
          window.GlobalData.getItem(window.GlobalData.FILTER_LABEL)
        );
        const selectedLabelsArr = labels.filter((t) => labelsArr.indexOf(t.value) != -1);

        this.setState({
          labels: labels,
          selectedLabels: selectedLabelsArr,
        });
      });
    } else {
      return null;
    }
  };

  onToggle = (name) => {
    this.setState({
      [name]: !this.state[name],
    });
  };

  onNewTraningClick = () => {
    window.TopMenu.Self.setSideMenuVisible(false);
    //let trainItem = {
    //    id: "create",
    //    horseName: null,
    //    horseTrainer: null,
    //    trainId: null,
    //    trainerId: null,
    //    trainType: null,
    //    horseId: null
    //};
    //new window.trainingPopup(trainItem);
    var currentTrainer = window.DataStorage.allUsers.find((i) => i.Id == window.myid);
    let options = {
      trainerId: currentTrainer.Id,
      trainerName: currentTrainer.Name,
    };
    new TrainingPopup().showPopup(options, null, () => {});
  };

  onDeleteTraningClick = () => {
    window.TopMenu.Self.setSideMenuVisible(false);
    window.Training.prototype.showDeletePopup();
  };

  onCopyTraningClick = () => {
    window.TopMenu.Self.setSideMenuVisible(false);
    window.$("#mainPageCopy").popup();
    window.$("#mainPageCopy").trigger("create");
    window.$("#mainPageCopy").popup("open");
  };

  onLanguageClick = () => {
    window.TopMenu.Self.setSideMenuVisible(false);
    new window.LanguagePage().show();
  };

  onHelpClick = () => {
    window.open("https://www.eqapp.dk/faq", "_blank");
  };

  onContactClick = () => {
    window.TopMenu.Self.setSideMenuVisible(false);
    window.showPage("#contactUsPage");
  };

  onLogoutClick = () => {
    window.onLogout();
  };

  onOrderSelectChange = (e) => {
    window.TopMenu.Self.setSideMenuVisible(false);

    const val = e.target.value;
    window.GlobalData.setItem(window.GlobalData.ORDER_BY, val);

    if (window.training) window.training.refresh();
  };

  onRiderSelectChange = (riderArr) => {
    //window.TopMenu.Self.setSideMenuVisible(false);
    if (Array.isArray(riderArr) && riderArr.length) {
      const valArr = riderArr.map((item) => item.value);
      let selectedRiderArr = [];
      if (valArr[valArr.length - 1] == 1) {
        window.GlobalData.setItem(window.GlobalData.FILTER_RIDER, JSON.stringify([1]));
        selectedRiderArr[0] = riderArr[riderArr.length - 1];
      } else {
        selectedRiderArr = riderArr.filter((i) => i.value != 1);
        const valArr = selectedRiderArr.map((i) => i.value);
        window.GlobalData.setItem(window.GlobalData.FILTER_RIDER, JSON.stringify(valArr));
      }
      this.setState({
        riderValue: selectedRiderArr,
      });
    } else {
      window.GlobalData.setItem(window.GlobalData.FILTER_RIDER, JSON.stringify([1]));
      this.setState({
        riderValue: { key: 1, value: 1, label: "All riders" },
      });
    }
    window.Training.needRefresh = true;
    if (window.training) window.training.refresh();
    if (window.location.hash == "#homePage") window.HomePage.prototype.showHorses();
  };

  onHorseTypeChange = (newValue) => {
    //window.TopMenu.Self.setSideMenuVisible(false);
    if (Array.isArray(newValue) && newValue.length) {
      const valArr = newValue.map((item) => item.value);
      let selectedHorseTypeArr = [];
      if (
        valArr[valArr.length - 1] == 1 ||
        valArr[valArr.length - 1] == 2 ||
        valArr[valArr.length - 1] == 3 ||
        valArr[valArr.length - 1] == 101
      ) {
        window.GlobalData.setItem(
          window.GlobalData.SHOW_IN_TRAINING,
          JSON.stringify([valArr[valArr.length - 1]])
        );
        selectedHorseTypeArr[0] = newValue[newValue.length - 1];
        window.DataStorage.clearAllHorses();
        window.training.loadHorseData(() => {
          window.training.loadCache();
          if (window.location.hash == "#homePage") window.HomePage.prototype.showHorses();
        });
      } else {
        selectedHorseTypeArr = newValue.filter(
          (i) => i.value != 1 && i.value != 2 && i.value != 3 && i.value != 101
        );
        const valArr = selectedHorseTypeArr.map((i) => i.value);
        window.GlobalData.setItem(
          window.GlobalData.SHOW_IN_TRAINING,
          JSON.stringify(valArr)
        );
      }

      this.setState({
        selectedHorseType: selectedHorseTypeArr,
      });

      if (window.HomePage.horseFilterCallback)
        window.HomePage.horseFilterCallback(window.HomePage.FILTER_HORSE_TYPE, valArr);
      if (window.training) window.training.refresh();
    } else {
      window.GlobalData.setItem(window.GlobalData.SHOW_IN_TRAINING, JSON.stringify([1]));
      window.DataStorage.clearAllHorses();
      window.training.loadHorseData(() => {
        window.training.loadCache();
        if (window.location.hash == "#homePage") window.HomePage.prototype.showHorses();
      });
      this.setState({
        selectedHorseType: { key: 1, value: 1, label: "All horse types" },
      });
      if (window.HomePage.horseFilterCallback)
        window.HomePage.horseFilterCallback(window.HomePage.FILTER_HORSE_TYPE, 1);
      if (window.training) window.training.refresh();
    }
  };

  onStableChange = (stableArr) => {
    //window.TopMenu.Self.setSideMenuVisible(false);
    if (Array.isArray(stableArr) && stableArr.length) {
      const valArr = stableArr.map((item) => item.value);
      let selectedStableArr = [];
      if (valArr[valArr.length - 1] == 1) {
        window.GlobalData.setItem(window.GlobalData.SHOW_IN_STABLE, JSON.stringify([1]));
        selectedStableArr[0] = stableArr[stableArr.length - 1];
      } else {
        selectedStableArr = stableArr.filter((i) => i.value != 1);
        const valArr = selectedStableArr.map((i) => i.value);
        window.GlobalData.setItem(
          window.GlobalData.SHOW_IN_STABLE,
          JSON.stringify(valArr)
        );
      }
      this.setState({
        selectedStable: selectedStableArr,
      });

      if (window.HomePage.horseFilterCallback)
        window.HomePage.horseFilterCallback(window.HomePage.FILTER_HORSE_STABLE, valArr);
      if (window.training) window.training.refresh();
    } else {
      window.GlobalData.setItem(window.GlobalData.SHOW_IN_STABLE, JSON.stringify([1]));
      this.setState({
        selectedStable: { key: 1, value: 1, label: "All Stables" },
      });
      if (window.HomePage.horseFilterCallback)
        window.HomePage.horseFilterCallback(window.HomePage.FILTER_HORSE_STABLE, 1);
      if (window.training) window.training.refresh();
    }
  };

  onLabelChange = (labelArr) => {
    if (Array.isArray(labelArr) && labelArr.length) {
      const valArr = labelArr.map((item) => item.value);
      let selectedLabelArr = [];
      if (valArr[valArr.length - 1] == 1) {
        window.GlobalData.setItem(window.GlobalData.FILTER_LABEL, JSON.stringify([1]));
        selectedLabelArr[0] = labelArr[labelArr.length - 1];
      } else {
        selectedLabelArr = labelArr.filter((i) => i.value != 1);
        const valArr = selectedLabelArr.map((i) => i.value);
        window.GlobalData.setItem(window.GlobalData.FILTER_LABEL, JSON.stringify(valArr));
      }
      this.setState({
        selectedLabels: selectedLabelArr,
      });
    } else {
      window.GlobalData.setItem(window.GlobalData.FILTER_LABEL, JSON.stringify([1]));
      this.setState({
        selectedLabels: { key: 1, value: 1, label: "All Labels" },
      });
    }
    if (window.location.hash == "#homePage") window.HomePage.prototype.showHorses();
    if (window.training) window.training.refresh();
  };

  render() {
    const {
      showTrianingGroup,
      showFilterGroup,
      riders,
      riderValue,
      horseTypes,
      selectedHorseType,
      stables,
      selectedStable,
      labels,
      selectedLabels,
    } = this.state;
    const orderDefaultValue = window.GlobalData.getItem(window.GlobalData.ORDER_BY);

    return (
      <div>
        <div
          className="sideMenuHeader"
          onClick={() => this.onToggle("showTrianingGroup")}
        >
          <span>Training</span>
          <img
            src="img/keyboard_arrow_down.svg"
            className={showTrianingGroup ? "sideMenuImageRotated" : ""}
            style={{ width: "24px" }}
          />
        </div>
        {showTrianingGroup && (
          <div style={{ marginBottom: "15px" }}>
            <div className="sideMenuTextItem" onClick={this.onNewTraningClick}>
              New
            </div>
            <div className="sideMenuTextItem" onClick={this.onDeleteTraningClick}>
              Delete
            </div>
            <div className="sideMenuTextItem" onClick={this.onCopyTraningClick}>
              Copy
            </div>
          </div>
        )}
        <div className="sideMenuLine"></div>

        <div className="sideMenuHeader" onClick={() => this.onToggle("showFilterGroup")}>
          <span>Filter</span>
          <img
            className={showFilterGroup ? "sideMenuImageRotated" : ""}
            style={{ width: "24px" }}
            src="img/keyboard_arrow_down.svg"
          />
        </div>
        {showFilterGroup && (
          <div id="sideFiltersDiv" style={{ marginBottom: "15px" }}>
            <div id="showHorsesInTrainingContainer" style={{ marginBottom: "5px" }}>
              <Select
                isMulti
                className="sideMenuSelect"
                value={selectedHorseType}
                options={horseTypes}
                onChange={this.onHorseTypeChange}
              />
            </div>
            <div id="showHorsesInStableContainer" style={{ marginBottom: "5px" }}>
              <Select
                isMulti
                className="sideMenuSelect"
                value={selectedStable}
                options={stables}
                onChange={this.onStableChange}
              />
            </div>
            <div id="showHorsesFilterRiderContainer" style={{ marginBottom: "5px" }}>
              <Select
                isMulti
                className="sideMenuSelect"
                value={riderValue}
                onChange={this.onRiderSelectChange}
                options={riders}
              />
            </div>
            <div id="showHorsesFilterLabelContainer" style={{ marginBottom: "5px" }}>
              <Select
                isMulti
                className="sideMenuSelect"
                value={selectedLabels}
                onChange={this.onLabelChange}
                options={labels}
              />
            </div>
            <div style={{ marginBottom: "5px" }}>
              <select
                data-role="none"
                onChange={this.onOrderSelectChange}
                defaultValue={orderDefaultValue}
              >
                <option value="1">{window.lang.alfabetic}</option>
                <option value="2">{window.lang.timeAlfabetic}</option>
                <option value="4">{window.lang.timeTop}</option>
                <option value="3">{window.lang.timeTopCompact}</option>
                <option value="5">Alfabetica compact</option>
              </select>
            </div>
          </div>
        )}
        <div className="sideMenuLine"></div>

        <select
          id="trainDaysNumber"
          defaultValue={-5}
          data-role="none"
          style={{ marginTop: "15px", marginBottom: "15px" }}
        >
          <option id="oneDayOwnHorsesOpt" value="-3" vindex="5">
            1 day only own horses
          </option>
          <option id="ownHorses" value="-6" vindex="2">
            Own horses
          </option>
          <option id="riderOpt_2" value="-4" vindex="0">
            Riders
          </option>
          <option id="weekOpt_2" value="-5" vindex="1">
            Week 2
          </option>
          <option id="oneDay" value="1" vindex="3">
            1
          </option>
          <option id="twoDays" value="2" vindex="4">
            2
          </option>
          <option id="rider_2" value="-10" vindex="">
            Rider2
          </option>
        </select>
        <div className="sideMenuLine"></div>

        <div className="sideMenuTextItem" onClick={this.onLanguageClick}>
          Lang
        </div>
        <div className="sideMenuTextItem" onClick={this.onHelpClick}>
          Help
        </div>
        <div className="sideMenuTextItem" onClick={this.onContactClick}>
          Contact
        </div>
        <div
          className="sideMenuTextItem"
          onClick={this.onLogoutClick}
          style={{ fontSize: "1.2em", color: "#FF8811", fontWeight: "700" }}
        >
          Logout
        </div>
      </div>
    );
  }
}

export class SideMenuExtention {
  renderSideMenu() {
    ReactDOM.render(<SideMenu />, document.getElementById("sideMenuDiv"));
  }

  refreshSideMenu() {
    ReactDOM.render(
      <SideMenu time={new Date()} />,
      document.getElementById("sideMenuDiv")
    );
  }
}
